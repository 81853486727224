<template>
  <div class="ceateSheet">
    <div class="header">
      <p @click="goBack()">
        <i class="el-icon-arrow-left"></i> 答题卡 / 创建答题卡模版
      </p>
    </div>
    <el-form
      ref="sheetForm"
      :model="sheetData"
      :rules="sheetRules"
      label-width="0"
      class="demo-ruleForm"
    >
      <el-form-item label="阅卷方式：" prop="markType">
        <!-- createMarkTypeList -->
        <el-radio-group v-model="sheetData.markType" @change="setMarkType()">
          <el-radio
            v-for="(v, i) in createMarkTypeList"
            :key="i"
            :label="v.value"
          >
            {{ v.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="模版名称：" prop="name">
        <el-input
          v-model="sheetData.name"
          style="width: 500px"
          placeholder="请输入模板名称"
          maxlength="50"
          show-word-limit
          class="text-nums"
        ></el-input>
      </el-form-item>
      <el-form-item label="入学年份：">
        <el-select
          v-model="sheetData.year"
          style="width: 250px"
          placeholder="请选择入学年份"
          clearable
        >
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学段：">
        <el-select
          v-model="sheetData.levelId"
          style="width: 250px"
          placeholder="学段"
          clearable
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属科目：" prop="subjectId">
        <el-radio-group v-model="sheetData.subjectId">
          <el-radio
            v-for="(v, i) in subjectOptions"
            :key="i"
            :label="v.value"
            >{{ v.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="打印纸张：" prop="paperType" class="is-required">
        <div class="img-item-box">
          <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': sheetType == 1 }"
              @click="checkKey(1, 'sheetType')"
            >
              <img src="@/static/sheet/webCreate/A4.png" alt="" />
            </div>
            <p>A4</p>
          </div>
          <!-- <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': sheetType == 2 }"
              @click="checkKey(2, 'sheetType')"
            >
              <img src="@/static/sheet/webCreate/twoColumn.png" alt="" />
            </div>
            <p>[B4/8K]两栏</p>
          </div>
          <div v-if="!sheetData.markType" class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': sheetType == 3 }"
              @click="checkKey(3, 'sheetType')"
            >
              <img src="@/static/sheet/webCreate/threeColumn.png" alt="" />
            </div>
            <p>[B4/8K]三栏</p>
          </div> -->
          <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': sheetType == 4 }"
              @click="checkKey(4, 'sheetType')"
            >
              <img src="@/static/sheet/webCreate/twoColumn.png" alt="" />
            </div>
            <p>A3两栏</p>
          </div>
          <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': sheetType == 5 }"
              @click="checkKey(5, 'sheetType')"
            >
              <img src="@/static/sheet/webCreate/threeColumn.png" alt="" />
            </div>
            <p>A3三栏</p>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="正/背面：">
        <el-checkbox v-model="isBack">作为答题卡的背面？</el-checkbox>
      </el-form-item> -->
      <el-form-item label="答题卡头：" prop="sheetHeader">
        <el-checkbox-group v-model="sheetData.sheetHeader">
          <el-checkbox label="1">画准考证号</el-checkbox>
          <el-checkbox label="2" :disabled="sheetData.markType == 1">
            画贴条码区
          </el-checkbox>
          <el-checkbox label="3">划分A B卷</el-checkbox>
          <el-checkbox v-if="sheetData.markType == 1" label="4">
            装订线
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="选择题排版：">
        <div class="img-item-box choose-item">
          <!-- <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': objectiveType == 1 }"
              @click="checkKey(1, 'objectiveType')"
            >
              <img src="@/static/sheet/webCreate/chooseOne.png" alt="" />
            </div>
            <p>按大题分隔，横向顺序排版</p>
            <p class="err">【客观题只能添加在左侧第一栏】</p>
          </div> -->
          <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': objectiveType == 2 }"
              @click="checkKey(2, 'objectiveType')"
            >
              <img src="@/static/sheet/webCreate/chooseTwo.png" alt="" />
            </div>
            <p>不划分大题，竖向分组，横向排版</p>
            <p>
              <el-input
                v-model="sheetData.objectiveCount"
                placeholder="请输入内容"
                oninput="value=value.replace(/[^0-9]/g,'')"
                class="objective-count"
                @input="setChangeNum"
                @blur="sheetData.objectiveCount = $event.target.value"
              >
              </el-input>
              道题为一组
            </p>
          </div>
          <div class="img-item">
            <div
              class="img-box"
              :class="{ 'item-on': objectiveType == 3 }"
              @click="checkKey(3, 'objectiveType')"
            >
              <img src="@/static/sheet/webCreate/chooseThree.png" alt="" />
            </div>
            <p>不划分答题，横向分组，竖向排版</p>
            <p>
              <el-input
                v-model="sheetData.objectiveCount"
                oninput="value=value.replace(/[^0-9]/g,'')"
                placeholder="请输入内容"
                class="objective-count"
                @input="setChangeNum"
                @blur="sheetData.objectiveCount = $event.target.value"
              >
              </el-input>
              道题为一组
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="sub-btn-box">
      <el-button type="primary" plain @click="submitSheet">创建</el-button>
    </div>
  </div>
</template>

<script>
import { getSubjectList } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import { createMarkTypeList } from "@/core/util/sheetPaper";
import { addExamPapersheet } from "@/core/api/exam/sheet";
const nowYear = new Date().getFullYear();
const arr = new Array(nowYear - 2014).fill("");
let yearList = arr.map((item, index) => {
  return { label: index + 2015, value: index + 2015 };
});
export default {
  name: "CeateSheet",
  data() {
    return {
      levelOptions: levelOptions(),
      yearList: yearList.reverse(),
      sheetRules: {
        name: [
          { required: true, message: "请输入模版名称", trigger: "blur" },
          { min: 3, message: "至少三个字符", trigger: "blur" },
        ],
        subjectId: [
          { required: true, message: "请选择所属科目", trigger: "change" },
        ],
        sheetHeader: [
          { required: true, message: "请选择答题卡卡头", trigger: "change" },
        ],
        markType: [
          { required: true, message: "请选择答题卡类型", trigger: "change" },
        ],
        paperType: [{ required: false, message: "", trigger: "blur" }],
      },

      sheetData: {
        sheetHeader: ["1", "2"],
        name: "",
        year: "",
        levelId: "",
        markType: 0,
        isBack: "",
        // 客观题单组数量
        objectiveCount: 5,
        subjectId: "",
      },
      objectiveType: 2,
      isBack: false,
      sheetType: 4,
      subjectOptions: [],
      createMarkTypeList: createMarkTypeList,
    };
  },
  created() {
    this.subjectOptions = getSubjectList();
    this.sheetData.subjectId = this.subjectOptions[0].value;
  },
  methods: {
    setChangeNum() {
      if (this.sheetData.objectiveCount > 20) {
        this.sheetData.objectiveCount = this.sheetData.objectiveCount.substr(
          0,
          this.sheetData.objectiveCount.length - 1
        );
      }
    },
    goBack() {
      this.$router.push({
        name: "SheetList",
      });
    },
    addExamPapersheet(data) {
      addExamPapersheet(data).then((res) => {
        let data = {
          sheetId: res.data.data,
        };
        const routeData = this.$router.resolve({
          name: "SheetEdit",
          query: data,
        });
        window.open(routeData.href, "_blank");
        // this.$router.push({
        //   name: "SheetEdit",
        //   query: data,
        // });
        this.goBack();
      });
    },
    submitSheet() {
      let data = Object.assign({}, this.sheetData);
      if (!this.sheetData.objectiveCount) {
        this.$message({
          showClose: true,
          message: "请输入选择题数量",
          type: "warning",
        });
        return;
      }
      if (this.sheetData.objectiveCount > 20) {
        this.$message({
          showClose: true,
          message: "选择题组数量不能大于20",
          type: "warning",
        });
        return;
      }
      if (this.sheetType == 1) {
        data.paperType = 1;
        data.columnCount = 1;
      }
      if (this.sheetType == 2) {
        data.paperType = 3;
        data.columnCount = 2;
      }
      if (this.sheetType == 3) {
        data.paperType = 3;
        data.columnCount = 2;
      }
      if (this.sheetType == 4) {
        data.paperType = 2;
        data.columnCount = 2;
      }
      if (this.sheetType == 5) {
        data.paperType = 2;
        data.columnCount = 3;
      }
      data.objectiveType = this.objectiveType;
      if (this.isBack) {
        data.isBack = 1;
        data.pageCount = 1;
      } else {
        data.isBack = 0;
        data.pageCount = 2;
      }
      data.numberCount = 1;
      data.type = 0;
      data.sheetHeader = data.sheetHeader.join(",");
      // console.log(data);
      this.$refs.sheetForm.validate((valid) => {
        if (valid) {
          this.addExamPapersheet(data);
        } else {
          return false;
        }
      });
    },
    setMarkType() {
      if (this.sheetData.markType == 1) {
        this.sheetHeader = ["2", "3", "4"];
      } else {
        this.sheetHeader = ["2", "3"];
      }
    },
    checkKey(val, key) {
      this[key] = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.ceateSheet {
  .sub-btn-box {
    background-color: #ffffff;
    padding: 24px 24px 60px;
    text-align: center;
    margin-bottom: 60px;
  }
  .err {
    color: #ff0b0b !important;
  }
  .objective-count {
    width: 80px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 7px;
    .el-form-item__error {
      padding-top: 0;
    }
    .el-form-item__label {
      width: 120px !important;
      line-height: 32px;
      margin-bottom: 0;
      float: none;
      text-align: left;
    }
  }
  .el-radio {
    margin-top: 0.5rem;
  }
  .img-item-box {
    display: flex;

    .img-item {
      width: 68px;
      margin-right: 45px;
      text-align: center;
      .img-box {
        cursor: pointer;
        display: inline-block;
      }

      p {
        text-align: center;
        color: #101011;
        margin-top: 8px;
        line-height: 14px;
      }
    }
  }
  .choose-item {
    .img-item {
      width: 242px;
    }
  }
  .item-on {
    position: relative;
    &::after {
      position: absolute;
      border: 2px solid #2474ed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: "";
    }
  }
  .demo-ruleForm {
    padding: 24px;

    margin-top: 24px;
    background-color: #ffffff;
  }
  .header {
    padding: 24px;
    margin: 24px 0;
    background-color: #ffffff;
    p {
      margin: 0;
      cursor: pointer;
    }
  }
}
</style>
